<template>
    <modal ref="modalCambiarUnCedis" :titulo="titulo" icon="money" tamano="modal-lg" :cargando="loading" @guardar="aceptar">
        <ValidationObserver ref="validator">
            <div class="row mx-0 mb-1 justify-center">
                <ValidationProvider v-slot="{errors}" tag="div" class="col-5" :rules="`required|greaterThanZero|max_value:16777215|promo:${model.promo_valor},${model.promo_valor_formateado}`" name="valor">
                    <label class="pl-3 text-muted"> Nuevo Valor </label>
                    <div class="d-middle">
                        <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                            {{ sigla_actual }}
                        </div>
                        <input-miles-moneda v-model="model.valor" :id-moneda="id_moneda_cedis" maxlength="13" />
                    </div>
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    <div v-if="model.id_promocion != null" class="row mx-0">
                        <div class="bg-light-f5 text-general border mt-1 cr-pointer br-8 border d-middle-center px-3" @click="eliminarPromocion">
                            <i class="icon-tag-remove" />
                            <p class="pl-3">Eliminar promoción</p>
                        </div>
                    </div>
                </ValidationProvider>
                <!-- <div class="col-5">
                </div> -->
                <div class="col-5">
                    <!-- smallint(5) -->
                    <ValidationProvider v-slot="{errors}" rules="numeric|max_value:65535" name="Cantidad maxima">
                        <label class="pl-3 text-muted"> Cantidad maxima de compra </label>
                        <el-input v-model="model.maxima_compra_permitida" class=" w-100" maxlength="6" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mb-3 justify-center">
                <div class="col-5">
                    <ValidationProvider v-slot="{errors}" rules="required|max_value:65535" name="Stock Mín. Vendedor">
                        <label class="pl-3 text-muted"> Stock Mín. Vendedor </label>
                        <el-input v-model="model.stock_minimo_tienda" class=" w-100" maxlength="6" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-5">
                    <!-- smallint(5) -->
                    <ValidationProvider v-slot="{errors}" rules="required|max_value:65535" name="Stock Mínimo">
                        <label class="pl-3 text-muted"> Stock Mínimo </label>
                        <el-input v-model="model.stock_minimo_cedis" class=" w-100" maxlength="6" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-1 ml-5 mb-3 justify-center">
                <div class="col-1">
                    <el-checkbox v-model="model.disponible_cedi" class="check-red" :true-label="1" :false-label="0" />
                </div>
                <div class="col-10 px-0">
                    <p class="text-general">
                        Este producto está disponible para la venta en este Cedis.
                    </p>
                </div>
            </div>
            <template v-if="model.disponible_cedi == 1">
                <div class="row mx-0 pl-5 mb-3">
                    <div class="col-12 px-0">
                        <div class="row mx-0 mb-3">
                            <div class="col-1 px-2" />
                            <div class="col text-general f-500 f-16 px-0">
                                Impuesto del producto
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-1 ml-5">
                                <input v-model="model.id_impuesto" :value="0" type="radio" class="option-input radio black" name="Efectivo" />
                            </div>
                            <div class="col-5 pl-0 d-middle mb-3 text-general">
                                Ninguno
                            </div>
                        </div>
                        <div v-for="(impuesto, i) in impuestos" :key="i" class="row mx-0">
                            <div class="col-12 px-0">
                                <div class="row mx-0">
                                    <div class="col-1 ml-5">
                                        <input v-model="model.id_impuesto" :value="impuesto.id" type="radio" class="option-input radio black" name="Efectivo" />
                                    </div>
                                    <div class="col pl-0 d-middle mb-3 text-general">
                                        {{ impuesto.nombre }} ({{ agregarSeparadoresNumero(impuesto.porcentaje,1) }}%)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="row mb-3 ml-5 justify-center">
                <div class="col-1 ">
                    <el-checkbox v-model="model.venta_sin_stock" class="check-red" :true-label="1" :false-label="0" />
                </div>
                <div class="col-10 px-0 text-general">
                    Permite vender sin stock.
                </div>
            </div>
            <div class="row mx-0 mb-3 ml-5 justify-center">
                <div class="col-1 ">
                    <el-checkbox v-model="model.inventario_proveedor" class="check-red" :true-label="1" :false-label="0" />
                </div>
                <div class="col-5 px-0 d-middle">
                    <p class="text-general">El inventario lo tiene directamente el proveedor.</p>
                </div>
                <div class="col-5">
                    <label class="pl-3 text-general"> Proveedor </label>
                    <el-select v-model="model.id_proveedor" size="small" filterable clearable class="w-100">
                        <el-option
                        v-for="item in proveedores"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import CedisProductos from '~/services/cedis/cedis_productos'

export default {
    data(){
        return{
            loading:false,
            model:{
                valor: null,
                stock_minimo_tienda: null,
                stock_minimo_cedis: null,
                id_producto: null,
                inventario_proveedor: null,
                disponible_cedi: null,
                venta_sin_stock: null,
                id_cedis: null,
                id_proveedor: null,
                id_promocion: null,
                promo_valor: null,
                promo_descuento: null,
                promo_valor_formateado: null,
                id_impuesto: 0,
                maxima_compra_permitida: 0
            },
            proveedores: [],
            impuestos: [],
            id_moneda_cedis: 0,
            titulo: '',
            tipo: 1
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            monedas:'cedis/monedas',
            id_moneda:'cedis/id_moneda',
            id_cedis_navbar:'cedis/id_cedis_navbar',
            cedis:'cedis/cedis',
            id_cedis:'cedis/id_cedis'
        }),
        sigla_actual(){
            let cedis = this.cedis.find(e => e.id== this.model.id_cedis )
            var sigla = '-'
            if(cedis){
                let moneda = this.monedas.find(e => e.id=== cedis.idm_moneda )
                if(moneda){
                    sigla = moneda.sigla
                }
            }
            return sigla
        },

    },
    methods: {
        toggle(info){
            this.titulo = `Cambiar en cedis: ${info.nombre}`
            this.impuestos = info.impuestos
            this.model.id_impuesto = info.impuestos.length == 0 ? 0 : info.productos_impuestos && info.productos_impuestos.length != 0 ? info.productos_impuestos[0].id : 0
            this.model.id_cedis = info.id
            this.moneda_cedis()
            this.listaProveedores()
            this.model.stock_minimo_tienda = info.stock_minimo_tienda
            this.model.stock_minimo_cedis = info.stock_minimo_cedis
            this.model.valor = info.valor
            this.model.maxima_compra_permitida = info.maxima_compra_permitida
            this.model.inventario_proveedor = !!info.inventario_proveedor
            this.model.disponible_cedi = !!info.disponible_cedi
            this.model.venta_sin_stock = !!info.venta_sin_stock
            this.model.id_proveedor = info.id_proveedor
            this.model.id_promocion = info.id_promocion
            this.model.promo_valor = info.promo_valor
            this.model.promo_descuento = info.promo_descuento
            this.model.promo_valor_formateado = this.separadorNumero(info.promo_valor)
            this.model.id_producto = this.id_producto
            this.$refs.modalCambiarUnCedis.toggle();
        },
        async aceptar(){
            try {
                const valid =  await this.$refs.validator.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'Campos obligatorios', 'warning')
                    return false
                }
                this.loading = true
                const {data} = await CedisProductos.actualizarCedisProductosValores(this.model)
                this.loading = false
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                this.$refs.modalCambiarUnCedis.toggle();

                this.$emit('actualizar')
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async listaProveedores(){
            try {

                const {data} = await CedisProductos.listaProveedores({id_cedis:this.model.id_cedis})
                this.proveedores = data.proveedores
            } catch (e){
                this.error_catch(e)
            }
        },
        moneda_cedis(){
            let cedis = this.cedis.find(e => e.id== this.model.id_cedis)
            if(cedis){
                let moneda = this.monedas.find(e => e.id=== cedis.idm_moneda )
                if(moneda){
                    this.id_moneda_cedis = moneda.id
                }
            }
        },
        async eliminarPromocion(cedi){
            try {
                const params = {
                    id_cedis: this.model.id_cedis,
                    id_producto: this.model.id_producto,
                    id_promocion: this.model.id_promocion,
                }
                this.loading = true
                const {data} = await CedisProductos.eliminarPromocion(params)
                this.notificacion('Mensaje', 'Datos actualizados', 'success')
                this.model.id_promocion = null
                this.model.promo_valor = null
            } catch (e){
                this.error_catch(e)
            }finally {
                this.loading = false
            }
        },
    }
}
</script>

<style>

</style>
